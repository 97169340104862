import React from "react"
import Base from "./Base"
import ComponentParser from "../particles/ComponentParser"
import Banner from "../organisms/banner/Banner"
import Breadcrumbs from "../organisms/breadcrumbs/Breadcrumbs"

const Page = ({ pageContext }) => {
  return (
    <Base context={pageContext} title={pageContext.title}>
      {!pageContext.isFrontPage ? (
        <>
          <Banner title={pageContext.title} />
          <Breadcrumbs />
        </>
      ) : null}
      <ComponentParser content={pageContext.blocks} />
    </Base>
  )
}

export default Page
